<template>
	<div id="app">
		<c-hero @showModal="onToggleModal"/>

		<section class="section section--feature">
			<div class="container-xl">
				<div class="row align-items-lg-center">
					<div class="col-12 col-lg-6 d-none d-lg-block">
						<div class="img-place">
							<img class="img-fluid" src="./assets/feature.jpg" alt="feature" />
						</div>
					</div>

					<div class="col-12 col-lg-6">
						<div class="features">
							<div class="section-heading">
								<h2 class="section-heading__title">Register to contine playing</h2>
							</div>

							<div class="features__inner">
								<div v-for="(oFeature, index) in aFeatures" :key="'f' + index"
									class="features__item"
								>
									<i class="features__img">
										<img :src="oFeature.img" alt="">
									</i>
									<div class="features__title">{{ oFeature.title }}</div>
									<p>{{ oFeature.content }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section section--profile">
			<div class="container-xl">
				<div class="section-heading">
					<h2 class="section-heading__title">Last added</h2>
				</div>

				<div class="profile">
					<div class="profile__slider" ref="glide">
						<div data-glide-el="track" class="glide__track">
							<div class="glide__slides">
								<div
									v-for="(oProfile, index) in aProfiles"
									:key="'glide__slide' + index"
									class="glide__slide"
								>
									<div class="profile__item"
										@click="onToggleModal"
									>
										<figure class="profile__img">
											<img :src="applyImgSrc(oProfile.photo)" :alt="oProfile.name + ' ' + oProfile.age" />
										</figure>
										<span class="profile__status">online</span>
										<div class="profile__info">
											<div class="profile__name">{{ oProfile.name }}</div>
											{{ oProfile.age }} years
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="glide__controls" data-glide-el="controls">
							<button class="glide__prev" data-glide-dir="<"></button>
							<button class="glide__next" data-glide-dir=">"></button>
						</div>
					</div>
				</div>
			</div>
		</section>

		<footer class="footer">
			<div class="container-xl">
				<div class="footer__line">
					<div class="row">
						<div class="col-12 col-sm-6 col-md-5 col-xl-6 offset-sm-6 offset-xl-5">
							<form-signup class="footer__form" />
						</div>
					</div>
				</div>

				<div class="footer__line">
					<p>&copy; Tender Woman 2016-{{ (new Date()).getFullYear() }}</p>
				</div>
			</div>
		</footer>

		<transition name="fade">
			<div v-if="!bHideModal" class="modal">
				<div class="modal__inner">
					<button class="modal__close" @click="onToggleModal" type="button"></button>

					<form-signup />
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import axios from 'axios';

import Glide     from '@glidejs/glide';
import scrolling from '@/utils/scrolling';

import Hero       from '@/components/Hero.vue';
import FormSignup from '@/components/FormSignup.vue';

export default {
	name: 'App',

	components: {
		'c-hero': Hero,
		'form-signup': FormSignup
	},

	data: function ()
	{
		return {
			bHideModal: true,

			aProfiles: [],
			aFeatures: Object.freeze([
				{
					img: require('@/assets/features/1.svg'),
					title: 'Add profile',
					content: 'Register an account. The registration procedure is fast and free. You need to provide your name, email address and agree to the terms. In a few seconds, you will be redirected to your home page.'
				},
				{
					img: require('@/assets/features/2.svg'),
					title: 'Profiles',
					content: 'Create your profile. This step is optional and you can add personal details at any time.'
				},
				{
					img: require('@/assets/features/3.svg'),
					title: 'Love stories',
					content: 'Enjoy pleasant communication. The Tender woman community is large and responsive. You can customize the filters according to your preferences and easily find the girl you are dreaming of.'
				}
			])
		};
	},

	created: async function ()
	{
		this.aProfiles = await this.fetchUsers();

		this.$nextTick(this.init);

		await this.sendPidAndSid();
	},

	mounted: function ()
	{
		
	},

	beforeDestroy: function ()
	{
		this.destroy();
	},

	methods: {
		onToggleModal: function (_value)
		{
			if (this.bHideModal)
			{
				scrolling.hide();
			}
			else
			{
				scrolling.restore();
			}

			this.bHideModal = !this.bHideModal;
		},

		fetchUsers: async function ()
		{
			var options = {
					method: 'post',
					url: '/api/?action=get_profiles'
				};

			try
			{
				var oResp = await axios(options);

				return Object.values(oResp.data);
			}
			catch (e)
			{
				console.error(e);

				return e;
			}
		},

		sendPidAndSid: async function ()
		{
			if (process.env.NODE_ENV === 'development') return;


			var oSearchParams = new URL(document.location).searchParams,
				sData = 'pid=' + oSearchParams.get('pid') + '&sid=' + oSearchParams.get('sid') + '&referer=' + document.referrer,
				options = {
					method: 'post',
					url: '/api/?action=send_partner_info',
					data: sData
				};

			try
			{
				await axios(options);
			}
			catch (e)
			{
				console.error(e);

				return e;
			}

			console.log("Success send pid/sid");
		},

		applyImgSrc: function (_sUrl)
		{
			return '//storage.victoriyaclub.com' + _sUrl;
		},

		init: function ()
		{
			var option = {
					type: 'carousel',
					perView: 4,
					gap: 30,
					keyboard: false,
					swipeThreshold: 10,
					dragThreshold: 30,
					autoplay: 5000,
					animationDuration: 500,
					animationTimingFunc: 'cubic-bezier(0,0,0.2,1)',
					breakpoints: {
						991: {
							perView: 3
						},
						767: {
							perView: 2.2
						},
						575: {
							perView: 1.5,
							gap: 10,
							focusAt: 'center'
						}
					}
				};

			var oSlider = this.slider = new Glide(this.$refs.glide, option);

			oSlider.mount();
		},

		destroy: function ()
		{
			if (this.slider && this.slider.destroy)
			{
				this.slider.destroy();
				this.slider = null;
			}
		}
	}
}
</script>

<style lang="scss">
@import "~@glidejs/glide/dist/css/glide.core.min.css";
@import './styles/style';

.section
{
	&--feature
	{
		background: url('~@/assets/feature-bg.png') no-repeat top center;

		&::before
		{
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 150px 150px;
			border-color: transparent transparent #DEE1ED transparent;
		}

		@media (min-width: 768px) and (max-width: 991.98px)
		{
			background-position: top left;
		}

		@media (min-width: 992px)
		{
			.img-place
			{
				margin-top: -70px;
				margin-bottom: -70px;

				img
				{
					vertical-align: middle;
				}

				@media (min-width: 768px)
				{
					margin-top: -120px;
					margin-bottom: -120px;
				}
			}
		}
	}

	&--profile
	{
		&::before
		{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 14vw 14vw 0 0;
			border-color: #DEE1ED transparent transparent transparent;
		}
	}
}

.features
{
	$offset-Y: 50px;

	max-width: 400px;
	margin-left: auto;
	margin-right: auto;

	&__inner
	{
		margin-bottom: $offset-Y * -1;
	}

	&__item
	{
		position: relative;
		margin-bottom: $offset-Y;
		padding-left: 80px;
	}

	&__img
	{
		position: relative;
		float: left;
		margin-left: -80px;
		height: 70px;

		img
		{
			display: inline-block;
			max-height: 100%;
		}
	}

	&__title
	{
		margin-bottom: 5px;
		line-height: 1;
		font-size: 2rem;
		font-weight: 700;
	}

	@media (min-width: 576px)
	{
		&__title
		{
			margin-bottom: 15px;
			font-size: 3rem;
		}

		&__item
		{
			padding-left: 100px;
		}

		&__img
		{
			margin-left: -100px;
		}
	}

	@media (min-width: 768px)
	{
		.section-heading
		{
			text-align: left;
		}

		&__title
		{
			margin-top: 20px;
		}
	}
}

.profile
{
	$offset-Y: 30px;

	&__inner
	{
		margin-bottom: $offset-Y * -1;
	}

	&__item
	{
		position: relative;
		margin-bottom: $offset-Y;
		cursor: pointer;
		background-color: #FFF;
		box-shadow: 6px 6px 15px rgba(80, 96, 127, 0.25);

		&:hover
		{
			img
			{
				transform: scale(1.2) translateZ(0);
			}
		}
	}

	&__status
	{
		position: absolute;
		top: 0;
		right: 0;
		width: 70px;
		line-height: 30px;
		font-size: 1.4rem;
		font-style: normal;
		text-align: center;
		color: #FFF;
		background: linear-gradient(180deg, #516B9D 0%, #23314A 100%);
		border-radius: 0px 0px 0px 5px;
		z-index: 1;
	}

	&__img
	{
		position: relative;
		overflow: hidden;
		clip-path: polygon(0 0%, 100% 0, 100% 100%, 0% 85%);
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;

		&::before
		{
			content: "";
			display: block;
			padding-top: percentage(330/270);
		}

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include transition(
				transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
			);
		}
	}

	&__info
	{
		margin-top: -10px;
		padding: 0 15px 15px;
		background: url('~@/assets/icon-webcam.svg') right 15px bottom 15px no-repeat;
		background-size: 26px 30px;
		font-size: 1.4rem;
	}

	&__name
	{
		margin-bottom: 5px;
		line-height: 1.2;
		font-size: 1.6rem;
		font-weight: 700;
	}

	.glide
	{
		&__track
		{
			margin: 0px -15px 0 -15px;
		}

		&__slides
		{
			padding: 0px 15px 0 10px;
		}
	}

	@media (min-width: 576px)
	{
		&__name
		{
			font-size: 2rem;
		}
	}

	@media (min-width: 992px)
	{
		&__name
		{
			font-size: 2.5rem;
		}
	}
}

.footer
{
	position: relative;
	background: #ECEDF1;
	background-size: cover;
	padding-top: 60px;
	padding-bottom: 60px;
	line-height: 1.2;
	text-align: center;

	&::before
	{
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 20vw 20vw;
		border-color: transparent transparent #DEE1ED transparent;
	}

	&__line
	{
		position: relative;

		&:first-child
		{
			padding-top: 40px;
			padding-bottom: 65px;
		}
	}

	@media (min-width: 576px)
	{
		text-align: right;
		background: #ECEDF1 url('~@/assets/footer-bg.jpg') no-repeat 20% top;

		&__line
		{
			&:first-child
			{
				padding-bottom: 115px;
			}
		}
	}
}

.glide
{
	&__controls
	{
		margin-top: 20px;
		font-size: 0;
		text-align: right;
	}

	&__prev,
	&__next
	{
		display: inline-block;
		vertical-align: top;
		width: 46px;
		height: 46px;
		background: linear-gradient(180deg, #7284A7 0%, #23314A 100%);
		cursor: pointer;
		border: none;
		border-radius: 5px;
		box-shadow: none;

		&::before
		{
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: url('~@/assets/icon-arrow.svg') 60% center no-repeat;
			background-size: 15px 30px;
		}
	}

	&__prev
	{
		&::before
		{
			transform: rotate(180deg);
		}
	}

	&__next
	{
		margin-left: 20px;
	}
}


.fade-enter-active,
.fade-leave-active {
	transition: opacity 300ms;
}

.fade-enter,
.fade-leave-to {
	transition: opacity 300ms;
	opacity: 0;
}

.modal
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	padding: 30px 0;
	text-align: center;
	background-color: rgba(#000, 0.4);
	overflow: auto;
	z-index: 10;
	backface-visibility: hidden;
	backdrop-filter: blur(10px);

	&::before
	{
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		height: 100%;
	}

	&__inner
	{
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 90%;
		max-width: 790px;
		padding: 75px 30px;
		background-color: #FFF;
		box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
	}

	&__close
	{
		position: absolute;
		top: 20px;
		right: 20px;
		padding: 35px 35px 0 0;
		background: linear-gradient(180deg, #6A7B9D 0%, #2A3852 100%);
		border: none;
		border-radius: 5px;
		cursor: pointer;

		&::before,
		&::after
		{
			content: "";
			position: absolute;
			background: #FFF;
			transform: rotate(45deg);
		}

		&::before
		{
			width: 2.4rem;
			height: 4px;
			top: 50%;
			left: 50%;
			margin-top: -2px;
			margin-left: -1.2rem;
		}

		&::after
		{
			width: 4px;
			height: 2.4rem;
			left: 50%;
			top: 50%;
			margin-top: -1.2rem;
			margin-left: -2px;
		}
	}
}
</style>
