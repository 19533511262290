<template>
	<div class="hero align-items-end align-items-sm-center">
		<img
			class="hero__logo"
			width="178"
			src="@/assets/logo.png"
			srcset="@/assets/logo@2x.png 2x, @/assets/logo@3x.png 3x"
			alt="Tender women"
		/>

		<div class="container-xl">
			<div class="row">
				<div class="col-12 col-md-6 col-lg-8 offset-md-6 offset-lg-4 offset-xl-3">
					<h1 class="hero__title">Are you looking for somebody special?</h1>
					
					<p class="hero__subtitle d-none d-sm-block">We can help you get acquainted with a beauty. Hundreds of attractive girls are waiting for you. Don’t hesitate and join the biggest dating platform.</p>

					<button type="button" class="custom-btn" @click="$emit('showModal')">Join us</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'hero'
}
</script>

<style lang="scss">
.hero
{
	position: relative;
	display: flex;
	background: url('~@/assets/hero-bg-sm.jpg') no-repeat center top;
	background-size: cover;
	min-height: 100vh;
	padding: 50px 0;
	text-align: center;

	&__logo
	{
		position: absolute;
		top: 15px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
	}

	&__title
	{
		line-height: 1.3;
		font-size: 4rem;
		color: #19243B;
	}

	&__subtitle
	{
		max-width: 400px;
		margin-top: 40px;
		margin-bottom: 50px;
	}

	@media (min-width: 576px)
	{
		background: url('~@/assets/hero-bg.jpg') no-repeat 15% top;
		text-align: left;

		&__logo
		{
			top: 40px;
			width: auto;
		}
	}

	@media (min-width: 768px)
	{
		
	}

	@media (min-width: 992px)
	{
		background-position: 25% top;

		&__title
		{
			font-size: 6rem;
		}
	}

	@media (min-width: 1200px)
	{
		&__title
		{
			font-size: 7rem;
		}

		&__subtitle
		{
			margin-bottom: 70px;
		}
	}
}
</style>
